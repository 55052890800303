/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: string;
};

export type GqlConversation = {
  __typename?: 'GQLConversation';
  id: Scalars['String'];
  lastMessage?: Maybe<GqlConversationMessage>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages: Array<GqlConversationMessage>;
  timeCreated: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type GqlConversationMessage = {
  __typename?: 'GQLConversationMessage';
  content: Scalars['String'];
  conversationId: Scalars['String'];
  finishReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  openaiId: Scalars['String'];
  role: Scalars['String'];
  timeCreated: Scalars['DateTime'];
  toolCallId?: Maybe<Scalars['String']>;
  toolCalls?: Maybe<Array<GqlConversationMessageToolCall>>;
};

export type GqlConversationMessageToolCall = {
  __typename?: 'GQLConversationMessageToolCall';
  function: GqlConversationMessageToolCallFunction;
  id: Scalars['String'];
  type: Scalars['String'];
};

export type GqlConversationMessageToolCallFunction = {
  __typename?: 'GQLConversationMessageToolCallFunction';
  arguments: Scalars['String'];
  name: Scalars['String'];
};

export type GqlConversationMessageUpserter = {
  content: Scalars['String'];
  conversationId: Scalars['String'];
  openaiId: Scalars['String'];
  role: Scalars['String'];
};

export type GqlMeUpdater = {
  avatarId?: InputMaybe<Scalars['String']>;
  currentRepoPath?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  verificationInput?: InputMaybe<GqlVerificationInput>;
};

export type GqlOrganization = {
  __typename?: 'GQLOrganization';
  avatarId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invitations: Array<GqlOrganizationInvitation>;
  name: Scalars['String'];
  permissions: Array<GqlOrganizationPermission>;
  secret?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  timeCreated: Scalars['DateTime'];
};

export type GqlOrganizationCreator = {
  avatarId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type GqlOrganizationInvitation = {
  __typename?: 'GQLOrganizationInvitation';
  admin: Scalars['Boolean'];
  email: Scalars['String'];
  id: Scalars['String'];
  organizationId: Scalars['String'];
  timeCreated: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type GqlOrganizationInvitationCreator = {
  admin: Scalars['Boolean'];
  email: Scalars['String'];
  organizationId: Scalars['String'];
};

export type GqlOrganizationInvitationUpdater = {
  admin?: InputMaybe<Scalars['Boolean']>;
};

export type GqlOrganizationPermission = {
  __typename?: 'GQLOrganizationPermission';
  admin: Scalars['Boolean'];
  id: Scalars['String'];
  organizationId: Scalars['String'];
  timeCreated: Scalars['DateTime'];
  user: GqlUser;
  userId: Scalars['String'];
};

export type GqlOrganizationPermissionCreator = {
  admin: Scalars['Boolean'];
  organizationId: Scalars['String'];
  userId: Scalars['String'];
};

export type GqlOrganizationPermissionUpdater = {
  admin?: InputMaybe<Scalars['Boolean']>;
};

export type GqlOrganizationUpdater = {
  avatarId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  secret?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type GqlUser = {
  __typename?: 'GQLUser';
  avatarId?: Maybe<Scalars['String']>;
  currentRepoPath?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  email: Scalars['String'];
  id: Scalars['String'];
  internal: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  organizations: Array<GqlOrganization>;
  phone?: Maybe<Scalars['String']>;
  timeCreated: Scalars['DateTime'];
};

export type GqlVerificationInput = {
  code: Scalars['String'];
  verificationId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createOrganization: GqlUser;
  createOrganizationInvitation: GqlOrganizationInvitation;
  createOrganizationPermission: GqlOrganizationPermission;
  deleteMe: GqlUser;
  deleteOrganizationInvitation: GqlOrganization;
  deleteOrganizationPermission: GqlOrganizationPermission;
  findOrCreateEmptyConversation: GqlConversation;
  removeMeAvatar: GqlUser;
  updateMe: GqlUser;
  updateMeWithVerification: GqlUser;
  updateOrganization: GqlOrganization;
  updateOrganizationInvitation: GqlOrganizationInvitation;
  updateOrganizationPermission: GqlOrganizationPermission;
  upsertConversationMessage: GqlConversationMessage;
};


export type MutationCreateOrganizationArgs = {
  creator: GqlOrganizationCreator;
};


export type MutationCreateOrganizationInvitationArgs = {
  creator: GqlOrganizationInvitationCreator;
};


export type MutationCreateOrganizationPermissionArgs = {
  creator: GqlOrganizationPermissionCreator;
};


export type MutationDeleteOrganizationInvitationArgs = {
  id: Scalars['String'];
};


export type MutationDeleteOrganizationPermissionArgs = {
  id: Scalars['String'];
};


export type MutationUpdateMeArgs = {
  updater: GqlMeUpdater;
};


export type MutationUpdateMeWithVerificationArgs = {
  code: Scalars['String'];
  verificationId: Scalars['String'];
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['String'];
  updater: GqlOrganizationUpdater;
};


export type MutationUpdateOrganizationInvitationArgs = {
  id: Scalars['String'];
  updater: GqlOrganizationInvitationUpdater;
};


export type MutationUpdateOrganizationPermissionArgs = {
  id: Scalars['String'];
  updater: GqlOrganizationPermissionUpdater;
};


export type MutationUpsertConversationMessageArgs = {
  upserter: GqlConversationMessageUpserter;
};

export type Query = {
  __typename?: 'Query';
  conversation: GqlConversation;
  conversationMessage: GqlConversationMessage;
  conversationMessages: Array<GqlConversationMessage>;
  conversations: Array<GqlConversation>;
  me?: Maybe<GqlUser>;
  organization: GqlOrganization;
  organizationInvitations: Array<GqlOrganizationInvitation>;
  organizationPermissions: Array<GqlOrganizationPermission>;
  system: GqlUser;
  user: GqlUser;
};


export type QueryConversationArgs = {
  id: Scalars['String'];
};


export type QueryConversationMessageArgs = {
  id: Scalars['String'];
};


export type QueryConversationMessagesArgs = {
  conversationId: Scalars['String'];
};


export type QueryOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationInvitationsArgs = {
  organizationId: Scalars['String'];
};


export type QueryOrganizationPermissionsArgs = {
  organizationId: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};

export type ConversationFragment = { __typename?: 'GQLConversation', id: string, lastMessageId?: string | null, timeCreated: string, userId: string };

export type ConversationMessageFragment = { __typename?: 'GQLConversationMessage', id: string, content: string, conversationId: string, timeCreated: string, role: string, toolCallId?: string | null, toolCalls?: Array<{ __typename?: 'GQLConversationMessageToolCall', id: string, type: string, function: { __typename?: 'GQLConversationMessageToolCallFunction', name: string, arguments: string } }> | null };

export type MeFragment = { __typename?: 'GQLUser', internal: boolean, isSystem: boolean, id: string, email: string, avatarId?: string | null, name: string, organizations: Array<{ __typename?: 'GQLOrganization', id: string, timeCreated: string, avatarId?: string | null, name: string, slug: string, secret?: string | null }> };

export type OrganizationFragment = { __typename?: 'GQLOrganization', id: string, timeCreated: string, avatarId?: string | null, name: string, slug: string, secret?: string | null };

export type OrganizationFullFragment = { __typename?: 'GQLOrganization', id: string, timeCreated: string, avatarId?: string | null, name: string, slug: string, secret?: string | null, invitations: Array<{ __typename?: 'GQLOrganizationInvitation', id: string, email: string, admin: boolean, userId: string }>, permissions: Array<{ __typename?: 'GQLOrganizationPermission', id: string, admin: boolean, organizationId: string, user: { __typename?: 'GQLUser', id: string, email: string, avatarId?: string | null, name: string } }> };

export type OrganizationInvitationFragment = { __typename?: 'GQLOrganizationInvitation', id: string, email: string, admin: boolean, userId: string };

export type OrganizationPermissionFragment = { __typename?: 'GQLOrganizationPermission', id: string, admin: boolean, organizationId: string, user: { __typename?: 'GQLUser', id: string, email: string, avatarId?: string | null, name: string } };

export type UserFragment = { __typename?: 'GQLUser', id: string, email: string, avatarId?: string | null, name: string };

export type CreateOrganizationVariables = Exact<{
  creator: GqlOrganizationCreator;
}>;


export type CreateOrganization = { __typename?: 'Mutation', createOrganization: { __typename?: 'GQLUser', internal: boolean, isSystem: boolean, id: string, email: string, avatarId?: string | null, name: string, organizations: Array<{ __typename?: 'GQLOrganization', id: string, timeCreated: string, avatarId?: string | null, name: string, slug: string, secret?: string | null }> } };

export type DeleteOrganizationInvitationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOrganizationInvitation = { __typename?: 'Mutation', deleteOrganizationInvitation: { __typename?: 'GQLOrganization', id: string, timeCreated: string, avatarId?: string | null, name: string, slug: string, secret?: string | null, invitations: Array<{ __typename?: 'GQLOrganizationInvitation', id: string, email: string, admin: boolean, userId: string }>, permissions: Array<{ __typename?: 'GQLOrganizationPermission', id: string, admin: boolean, organizationId: string, user: { __typename?: 'GQLUser', id: string, email: string, avatarId?: string | null, name: string } }> } };

export type FindOrCreateEmptyConversationVariables = Exact<{ [key: string]: never; }>;


export type FindOrCreateEmptyConversation = { __typename?: 'Mutation', findOrCreateEmptyConversation: { __typename?: 'GQLConversation', id: string, lastMessageId?: string | null, timeCreated: string, userId: string } };

export type UpdateMeVariables = Exact<{
  updater: GqlMeUpdater;
}>;


export type UpdateMe = { __typename?: 'Mutation', updateMe: { __typename?: 'GQLUser', internal: boolean, isSystem: boolean, id: string, email: string, avatarId?: string | null, name: string, organizations: Array<{ __typename?: 'GQLOrganization', id: string, timeCreated: string, avatarId?: string | null, name: string, slug: string, secret?: string | null }> } };

export type UpdateOrganizationVariables = Exact<{
  id: Scalars['String'];
  updater: GqlOrganizationUpdater;
}>;


export type UpdateOrganization = { __typename?: 'Mutation', updateOrganization: { __typename?: 'GQLOrganization', id: string, timeCreated: string, avatarId?: string | null, name: string, slug: string, secret?: string | null, invitations: Array<{ __typename?: 'GQLOrganizationInvitation', id: string, email: string, admin: boolean, userId: string }>, permissions: Array<{ __typename?: 'GQLOrganizationPermission', id: string, admin: boolean, organizationId: string, user: { __typename?: 'GQLUser', id: string, email: string, avatarId?: string | null, name: string } }> } };

export type UpdateOrganizationInvitationVariables = Exact<{
  id: Scalars['String'];
  updater: GqlOrganizationInvitationUpdater;
}>;


export type UpdateOrganizationInvitation = { __typename?: 'Mutation', updateOrganizationInvitation: { __typename?: 'GQLOrganizationInvitation', id: string, email: string, admin: boolean, userId: string } };

export type UpsertConversationMessageVariables = Exact<{
  upserter: GqlConversationMessageUpserter;
}>;


export type UpsertConversationMessage = { __typename?: 'Mutation', upsertConversationMessage: { __typename?: 'GQLConversationMessage', id: string, content: string, conversationId: string, timeCreated: string, role: string, toolCallId?: string | null, toolCalls?: Array<{ __typename?: 'GQLConversationMessageToolCall', id: string, type: string, function: { __typename?: 'GQLConversationMessageToolCallFunction', name: string, arguments: string } }> | null } };

export type FetchConversationsVariables = Exact<{ [key: string]: never; }>;


export type FetchConversations = { __typename?: 'Query', conversations: Array<{ __typename?: 'GQLConversation', id: string, lastMessageId?: string | null, timeCreated: string, userId: string }> };

export type FetchMeVariables = Exact<{ [key: string]: never; }>;


export type FetchMe = { __typename?: 'Query', me?: { __typename?: 'GQLUser', internal: boolean, isSystem: boolean, id: string, email: string, avatarId?: string | null, name: string, organizations: Array<{ __typename?: 'GQLOrganization', id: string, timeCreated: string, avatarId?: string | null, name: string, slug: string, secret?: string | null }> } | null };

export type FetchOrganizationVariables = Exact<{
  id: Scalars['String'];
}>;


export type FetchOrganization = { __typename?: 'Query', organization: { __typename?: 'GQLOrganization', id: string, timeCreated: string, avatarId?: string | null, name: string, slug: string, secret?: string | null, invitations: Array<{ __typename?: 'GQLOrganizationInvitation', id: string, email: string, admin: boolean, userId: string }>, permissions: Array<{ __typename?: 'GQLOrganizationPermission', id: string, admin: boolean, organizationId: string, user: { __typename?: 'GQLUser', id: string, email: string, avatarId?: string | null, name: string } }> } };

export const ConversationFragment = gql`
    fragment ConversationFragment on GQLConversation {
  id
  lastMessageId
  timeCreated
  userId
}
    `;
export const ConversationMessageFragment = gql`
    fragment ConversationMessageFragment on GQLConversationMessage {
  id
  content
  conversationId
  timeCreated
  role
  toolCalls {
    id
    type
    function {
      name
      arguments
    }
  }
  toolCallId
}
    `;
export const UserFragment = gql`
    fragment UserFragment on GQLUser {
  id
  email
  avatarId
  name
}
    `;
export const OrganizationFragment = gql`
    fragment OrganizationFragment on GQLOrganization {
  id
  timeCreated
  avatarId
  name
  slug
  secret
}
    `;
export const MeFragment = gql`
    fragment MeFragment on GQLUser {
  ...UserFragment
  internal
  isSystem
  organizations {
    ...OrganizationFragment
  }
}
    ${UserFragment}
${OrganizationFragment}`;
export const OrganizationInvitationFragment = gql`
    fragment OrganizationInvitationFragment on GQLOrganizationInvitation {
  id
  email
  admin
  userId
}
    `;
export const OrganizationPermissionFragment = gql`
    fragment OrganizationPermissionFragment on GQLOrganizationPermission {
  id
  admin
  organizationId
  user {
    ...UserFragment
  }
}
    ${UserFragment}`;
export const OrganizationFullFragment = gql`
    fragment OrganizationFullFragment on GQLOrganization {
  ...OrganizationFragment
  invitations {
    ...OrganizationInvitationFragment
  }
  permissions {
    ...OrganizationPermissionFragment
  }
}
    ${OrganizationFragment}
${OrganizationInvitationFragment}
${OrganizationPermissionFragment}`;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($creator: GQLOrganizationCreator!) {
  createOrganization(creator: $creator) {
    ...MeFragment
  }
}
    ${MeFragment}`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganization, CreateOrganizationVariables>;

/**
 * __useCreateOrganization__
 *
 * To run a mutation, you first call `useCreateOrganization` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganization` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganization, { data, loading, error }] = useCreateOrganization({
 *   variables: {
 *      creator: // value for 'creator'
 *   },
 * });
 */
export function useCreateOrganization(baseOptions?: Apollo.MutationHookOptions<CreateOrganization, CreateOrganizationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganization, CreateOrganizationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationHookResult = ReturnType<typeof useCreateOrganization>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganization>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganization, CreateOrganizationVariables>;
export const DeleteOrganizationInvitationDocument = gql`
    mutation DeleteOrganizationInvitation($id: String!) {
  deleteOrganizationInvitation(id: $id) {
    ...OrganizationFullFragment
  }
}
    ${OrganizationFullFragment}`;
export type DeleteOrganizationInvitationMutationFn = Apollo.MutationFunction<DeleteOrganizationInvitation, DeleteOrganizationInvitationVariables>;

/**
 * __useDeleteOrganizationInvitation__
 *
 * To run a mutation, you first call `useDeleteOrganizationInvitation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationInvitation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationInvitation, { data, loading, error }] = useDeleteOrganizationInvitation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationInvitation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationInvitation, DeleteOrganizationInvitationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationInvitation, DeleteOrganizationInvitationVariables>(DeleteOrganizationInvitationDocument, options);
      }
export type DeleteOrganizationInvitationHookResult = ReturnType<typeof useDeleteOrganizationInvitation>;
export type DeleteOrganizationInvitationMutationResult = Apollo.MutationResult<DeleteOrganizationInvitation>;
export type DeleteOrganizationInvitationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationInvitation, DeleteOrganizationInvitationVariables>;
export const FindOrCreateEmptyConversationDocument = gql`
    mutation FindOrCreateEmptyConversation {
  findOrCreateEmptyConversation {
    ...ConversationFragment
  }
}
    ${ConversationFragment}`;
export type FindOrCreateEmptyConversationMutationFn = Apollo.MutationFunction<FindOrCreateEmptyConversation, FindOrCreateEmptyConversationVariables>;

/**
 * __useFindOrCreateEmptyConversation__
 *
 * To run a mutation, you first call `useFindOrCreateEmptyConversation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindOrCreateEmptyConversation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findOrCreateEmptyConversation, { data, loading, error }] = useFindOrCreateEmptyConversation({
 *   variables: {
 *   },
 * });
 */
export function useFindOrCreateEmptyConversation(baseOptions?: Apollo.MutationHookOptions<FindOrCreateEmptyConversation, FindOrCreateEmptyConversationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FindOrCreateEmptyConversation, FindOrCreateEmptyConversationVariables>(FindOrCreateEmptyConversationDocument, options);
      }
export type FindOrCreateEmptyConversationHookResult = ReturnType<typeof useFindOrCreateEmptyConversation>;
export type FindOrCreateEmptyConversationMutationResult = Apollo.MutationResult<FindOrCreateEmptyConversation>;
export type FindOrCreateEmptyConversationMutationOptions = Apollo.BaseMutationOptions<FindOrCreateEmptyConversation, FindOrCreateEmptyConversationVariables>;
export const UpdateMeDocument = gql`
    mutation UpdateMe($updater: GQLMeUpdater!) {
  updateMe(updater: $updater) {
    ...MeFragment
  }
}
    ${MeFragment}`;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMe, UpdateMeVariables>;

/**
 * __useUpdateMe__
 *
 * To run a mutation, you first call `useUpdateMe` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMe` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMe, { data, loading, error }] = useUpdateMe({
 *   variables: {
 *      updater: // value for 'updater'
 *   },
 * });
 */
export function useUpdateMe(baseOptions?: Apollo.MutationHookOptions<UpdateMe, UpdateMeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMe, UpdateMeVariables>(UpdateMeDocument, options);
      }
export type UpdateMeHookResult = ReturnType<typeof useUpdateMe>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMe>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMe, UpdateMeVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($id: String!, $updater: GQLOrganizationUpdater!) {
  updateOrganization(id: $id, updater: $updater) {
    ...OrganizationFullFragment
  }
}
    ${OrganizationFullFragment}`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganization, UpdateOrganizationVariables>;

/**
 * __useUpdateOrganization__
 *
 * To run a mutation, you first call `useUpdateOrganization` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganization` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganization, { data, loading, error }] = useUpdateOrganization({
 *   variables: {
 *      id: // value for 'id'
 *      updater: // value for 'updater'
 *   },
 * });
 */
export function useUpdateOrganization(baseOptions?: Apollo.MutationHookOptions<UpdateOrganization, UpdateOrganizationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganization, UpdateOrganizationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationHookResult = ReturnType<typeof useUpdateOrganization>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganization>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganization, UpdateOrganizationVariables>;
export const UpdateOrganizationInvitationDocument = gql`
    mutation UpdateOrganizationInvitation($id: String!, $updater: GQLOrganizationInvitationUpdater!) {
  updateOrganizationInvitation(id: $id, updater: $updater) {
    ...OrganizationInvitationFragment
  }
}
    ${OrganizationInvitationFragment}`;
export type UpdateOrganizationInvitationMutationFn = Apollo.MutationFunction<UpdateOrganizationInvitation, UpdateOrganizationInvitationVariables>;

/**
 * __useUpdateOrganizationInvitation__
 *
 * To run a mutation, you first call `useUpdateOrganizationInvitation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationInvitation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationInvitation, { data, loading, error }] = useUpdateOrganizationInvitation({
 *   variables: {
 *      id: // value for 'id'
 *      updater: // value for 'updater'
 *   },
 * });
 */
export function useUpdateOrganizationInvitation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationInvitation, UpdateOrganizationInvitationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationInvitation, UpdateOrganizationInvitationVariables>(UpdateOrganizationInvitationDocument, options);
      }
export type UpdateOrganizationInvitationHookResult = ReturnType<typeof useUpdateOrganizationInvitation>;
export type UpdateOrganizationInvitationMutationResult = Apollo.MutationResult<UpdateOrganizationInvitation>;
export type UpdateOrganizationInvitationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationInvitation, UpdateOrganizationInvitationVariables>;
export const UpsertConversationMessageDocument = gql`
    mutation UpsertConversationMessage($upserter: GQLConversationMessageUpserter!) {
  upsertConversationMessage(upserter: $upserter) {
    ...ConversationMessageFragment
  }
}
    ${ConversationMessageFragment}`;
export type UpsertConversationMessageMutationFn = Apollo.MutationFunction<UpsertConversationMessage, UpsertConversationMessageVariables>;

/**
 * __useUpsertConversationMessage__
 *
 * To run a mutation, you first call `useUpsertConversationMessage` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertConversationMessage` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertConversationMessage, { data, loading, error }] = useUpsertConversationMessage({
 *   variables: {
 *      upserter: // value for 'upserter'
 *   },
 * });
 */
export function useUpsertConversationMessage(baseOptions?: Apollo.MutationHookOptions<UpsertConversationMessage, UpsertConversationMessageVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertConversationMessage, UpsertConversationMessageVariables>(UpsertConversationMessageDocument, options);
      }
export type UpsertConversationMessageHookResult = ReturnType<typeof useUpsertConversationMessage>;
export type UpsertConversationMessageMutationResult = Apollo.MutationResult<UpsertConversationMessage>;
export type UpsertConversationMessageMutationOptions = Apollo.BaseMutationOptions<UpsertConversationMessage, UpsertConversationMessageVariables>;
export const FetchConversationsDocument = gql`
    query FetchConversations {
  conversations {
    ...ConversationFragment
  }
}
    ${ConversationFragment}`;

/**
 * __useFetchConversations__
 *
 * To run a query within a React component, call `useFetchConversations` and pass it any options that fit your needs.
 * When your component renders, `useFetchConversations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConversations({
 *   variables: {
 *   },
 * });
 */
export function useFetchConversations(baseOptions?: Apollo.QueryHookOptions<FetchConversations, FetchConversationsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchConversations, FetchConversationsVariables>(FetchConversationsDocument, options);
      }
export function useFetchConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchConversations, FetchConversationsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchConversations, FetchConversationsVariables>(FetchConversationsDocument, options);
        }
export type FetchConversationsHookResult = ReturnType<typeof useFetchConversations>;
export type FetchConversationsLazyQueryHookResult = ReturnType<typeof useFetchConversationsLazyQuery>;
export type FetchConversationsQueryResult = Apollo.QueryResult<FetchConversations, FetchConversationsVariables>;
export const FetchMeDocument = gql`
    query FetchMe {
  me {
    ...MeFragment
  }
}
    ${MeFragment}`;

/**
 * __useFetchMe__
 *
 * To run a query within a React component, call `useFetchMe` and pass it any options that fit your needs.
 * When your component renders, `useFetchMe` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMe({
 *   variables: {
 *   },
 * });
 */
export function useFetchMe(baseOptions?: Apollo.QueryHookOptions<FetchMe, FetchMeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchMe, FetchMeVariables>(FetchMeDocument, options);
      }
export function useFetchMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchMe, FetchMeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchMe, FetchMeVariables>(FetchMeDocument, options);
        }
export type FetchMeHookResult = ReturnType<typeof useFetchMe>;
export type FetchMeLazyQueryHookResult = ReturnType<typeof useFetchMeLazyQuery>;
export type FetchMeQueryResult = Apollo.QueryResult<FetchMe, FetchMeVariables>;
export const FetchOrganizationDocument = gql`
    query FetchOrganization($id: String!) {
  organization(id: $id) {
    ...OrganizationFullFragment
  }
}
    ${OrganizationFullFragment}`;

/**
 * __useFetchOrganization__
 *
 * To run a query within a React component, call `useFetchOrganization` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrganization` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrganization({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchOrganization(baseOptions: Apollo.QueryHookOptions<FetchOrganization, FetchOrganizationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOrganization, FetchOrganizationVariables>(FetchOrganizationDocument, options);
      }
export function useFetchOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOrganization, FetchOrganizationVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOrganization, FetchOrganizationVariables>(FetchOrganizationDocument, options);
        }
export type FetchOrganizationHookResult = ReturnType<typeof useFetchOrganization>;
export type FetchOrganizationLazyQueryHookResult = ReturnType<typeof useFetchOrganizationLazyQuery>;
export type FetchOrganizationQueryResult = Apollo.QueryResult<FetchOrganization, FetchOrganizationVariables>;