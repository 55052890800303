import { cn } from '@/lib/utils';
import type { PropsWithChildren } from 'react';
import type { Spacing } from './VStack';
interface HStackProps {
  className?: string;
  spacing?: Spacing;
  alignment?: 'start' | 'center' | 'end' | 'baseline';
  onClick?: () => void;
}

/**
 * A horizontal stack of components, using flexbox.
 */
export function HStack(props: PropsWithChildren<HStackProps>) {
  const {
    className,
    spacing,
    alignment,
    onClick,
    children
  } = props;
  return <div className={cn('flex w-full flex-row', `items-${alignment ?? 'center'}`, spacing === 2 && 'space-x-2', spacing === 4 && 'space-x-4', className)} onClick={onClick} data-sentry-component="HStack" data-sentry-source-file="HStack.tsx">
      {children}
    </div>;
}