import isEmail from 'validator/lib/isEmail'

export function getChangedValues(values: any, initialValues: any) {
  return Object.entries(values).reduce((acc: any, [key, value]) => {
    const initialValue = initialValues[key]
    const compareValue = isNumber(value) ? String(value) : value
    const allNothing = [undefined, null, '', 0]
    if (allNothing.includes(initialValue) && allNothing.includes(compareValue as any)) {
      return acc
    }
    const hasChanged = initialValue != compareValue
    if (hasChanged) {
      acc[key as keyof typeof acc] = value
    }
    return acc
  }, {})
}

export function isValidUsername(username: string): boolean {
  const regexToTest = /^[a-z0-9-]*$/
  return regexToTest.test(username)
}

export function isValidVerificationCode(code: string): boolean {
  // checks if we have a 6 digit alphanumeric code
  return /^[A-Z0-9]{6}$/.test(code)
}

export function isValidDomain(domain: string): boolean {
  return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(domain)
}

export function isValidEmail(email: string): boolean {
  return isEmail(email)
  // Validate the basic email format first
  // const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  // if (!emailPattern.test(email)) {
  //   return false
  // }

  // // Extract the domain and TLD from the email
  // const domain = email.split('@')[1]
  // const domainParts = domain.split('.')
  // const tld = domainParts.slice(-1)[0] // Gets the last part (TLD)

  // // Check if TLD is in the list of valid TLDs
  // return ALL_TLDS.includes(tld.toLowerCase())
}

export function isValidPhone(phone: string): boolean {
  // matches (xxx) xxx-xxxx
  return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone)
}

export function isValidJson(jsonString: string): boolean {
  try {
    JSON.parse(jsonString)
    return true
  } catch {
    return false
  }
}

export function isNumber(n: any) {
  return !Number.isNaN(Number.parseFloat(n)) && !Number.isNaN(n - 0)
}
