import { OrganizationContext } from '@/components/auth/OrganizationContext';
import { UserContext } from '@/components/auth/UserContext';
import { useFetchOrganization, type OrganizationFullFragment } from '@/graphql/generated/query.types';
import { firstElement } from '@/lib/functions';
import { useRouter } from 'next/router';
import { useContext, useEffect, type PropsWithChildren } from 'react';

/**
 * Page component keeps track of the current user, and provides it to downstream components via the
 * OrganizationContext. If there is no user, renders the Login page.
 */
export function OrganizationWrapper({
  children
}: PropsWithChildren<unknown>) {
  const router = useRouter();
  const user = useContext(UserContext);
  const {
    slug
  } = router.query;
  const organization = user.organizations.find(org => org.slug === slug);
  const {
    data,
    previousData,
    loading,
    refetch
  } = useFetchOrganization({
    variables: {
      id: organization?.id ?? ''
    },
    skip: !organization
  });
  const organizationFragment: OrganizationFullFragment | null | undefined = data?.organization ?? previousData?.organization;
  const firstOrganization = firstElement(user.organizations);
  useEffect(() => {
    if (router.asPath === '/' && firstOrganization) {
      router.push(`/${firstOrganization.slug}`).catch(e => console.error(e));
    }
  }, [firstOrganization, router.asPath]);
  return organizationFragment ? <OrganizationContext.Provider value={{
    ...organizationFragment,
    refetch
  }} data-sentry-element="unknown" data-sentry-component="OrganizationWrapper" data-sentry-source-file="OrganizationWrapper.tsx">{children}</OrganizationContext.Provider> : null;
}