import { AuthWrapper } from '@/components/auth/AuthWrapper';
import { OrganizationWrapper } from '@/components/auth/OrganizationWrapper';
import { apolloClient } from '@/graphql/apollo';
import { cn } from '@/lib/utils';
import '@/styles/globals.css';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
export default function App({
  Component,
  pageProps
}: AppProps) {
  return <div className={cn('h-screen w-full overflow-hidden bg-[#e0e0e0] dark:bg-[#0a0a0a] font-sans antialiased'
  // fontSans.variable,
  // fontHeading.variable
  // GeistSans.variable,
  // GeistMono.variable
  )} data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <ThemeProvider attribute="class" defaultTheme="dark" enableSystem data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
        <ApolloProvider client={apolloClient} data-sentry-element="ApolloProvider" data-sentry-source-file="_app.tsx">
          <AuthWrapper data-sentry-element="AuthWrapper" data-sentry-source-file="_app.tsx">
            <OrganizationWrapper data-sentry-element="OrganizationWrapper" data-sentry-source-file="_app.tsx">
              <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
            </OrganizationWrapper>
          </AuthWrapper>
        </ApolloProvider>
      </ThemeProvider>
    </div>;
}