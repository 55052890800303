import type { OrganizationFullFragment } from '@/graphql/generated/query.types';
import React from 'react';

// For convenience, we use a non-nullable type since most of the application requires an authenticated organization.
// When the organization context has an undefined organization, the application will redirect to login and force authentication
// the rest of the application can render / receive a valid OrganizationContext.
export const OrganizationContext = React.createContext<OrganizationFullFragment & {
  refetch: () => void;
}>(undefined as any);
// https://reactjs.org/docs/context.html#contextdisplayname
OrganizationContext.displayName = 'OrganizationContext';